<template>
  <div class="documents_main">
      <div class="documents_main-header">
        <p class="title">טפסים להורדה</p>
      </div>
      <div v-if="!state" class="documents_main_btns">
          <div v-if="role>=99" @click="state='create document'" class="btn">
            <p>יצירת טופס</p>
          </div>
          <div @click="state='all documents'" class="btn">
            <p>טפסים</p>
          </div>
      </div>
      <div v-if="state" class="documents-states">
          <NewDocument v-if="state=='create document'" />
          <AllDocuments v-if="state=='all documents'" />
      </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import NewDocument from './NewDocument.vue'
import AllDocuments from './AllDocuments.vue'
import { onBeforeRouteLeave } from 'vue-router'
import store from '../../../store'
import { computed } from '@vue/runtime-core'

export default {
    components:{NewDocument,AllDocuments},
    setup(){
        const role = ref(computed(()=>{
            return store.state.role
        }))
        const state = ref(null)
        onBeforeRouteLeave((to,from,next)=>{
            if(state.value){
                state.value = null
                next(false)
            }else{
                next()
            }
        })
        return{state,role}
    }
}
</script>

<style scoped>
    .documents_main{
        width: 100%;
        height: 100%;
    }
    .documents_main-header{
        width: 100%;
        height: 30px;
    }
    .documents_main_btns{
        width: 100%;
        max-width: 500px;
        height: calc(100% - 30px);
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .title{
        font-size: 25px;
        color: var(--warning);
        text-align: center;
    }
    .btn{
        width: 80%;
        height: 150px;
        background:var(--purple);
        margin-bottom: 20px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .btn p{
        font-size: 30px;
        color: #fff;
    }
    .btn:active{
        transform: scale(0.98);
    }
    .documents-states{
        margin:0 auto;
        width: 100%;
        max-width: 500px;
        height: calc(100% - 30px);
    }
</style>