<template>
  <div class="new-document">
      <div class="new-document-field">
          <p class="title">שם הטופס</p>
          <el-input v-model="document_name" placeholder="שם הטופס" />
      </div>
      <div class="new-document-field">
          <el-button @click="handle_upload" style="width:100%;" type="primary">העלאת קובץ</el-button>
      </div>
      <div v-if="file" class="new-document-field">
          <div class="file-preview">
              <img v-if="file.name.includes('pdf') && !img_preview" src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/for_uploads%2Fpdf_375x375.png?alt=media&token=b5030a91-d3d9-4339-b9d5-d01bf8e075d8">
              <img v-if="file.name.includes('xlsx') && !img_preview" src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/for_uploads%2Fcsv_375x375.png?alt=media&token=ce6410c9-5108-4013-8fd6-f4f4ae884e62">
              <img v-if="img_preview" :src="img_preview">
          </div>
          <el-button @click="handle_submit" style="width:100%;" type="success">שליחת הטופס</el-button>
      </div>
      <input v-show="false" id="upload-image"  @change="handleChange" type="file">
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {slide_pop_error,alert} from '../../../Methods/Msgs'
import use_documents_storage from '../../../Methods/use_documents_storage'
import {projectFirestore} from '../../../firebase/config'
export default {
    setup(){
        const {url,filePath,uploadImage}  = use_documents_storage()
        const isPending = ref(false)
        const file = ref(null)
        const document_name = ref('')
        const img_preview = ref('')
        const handle_upload = ()=>{
            document.getElementById('upload-image').click()
        }
        const handleChange = async e => {
            const images_types = ["image/png", "image/jpeg", "image/jpg"];
            img_preview.value = ''
            isPending.value = true;
            const selected = e.target.files[0];
            file.value = selected;
            if (images_types.includes(file.value.type)) {
                const reader = new FileReader()
                reader.onload = (e) => {
                   img_preview.value = e.target.result
                }
                reader.readAsDataURL(file.value)
            }
            isPending.value = false;
        };
        const handle_submit = async()=>{
            if(!file.value){
                slide_pop_error('עליך להעלות מסמך')
                return
            }
            if(!document_name.value){
                slide_pop_error('עליך לרשום שם מסמך')
                return
            }
            isPending.value = true
            
            await uploadImage(file.value,document_name.value)
            const document = {
                created_at:new Date(),
                document_name:document_name.value,
                url:url.value,
                file_path:filePath.value
            }
            
            await save_document_in_db(document)

            isPending.value = false
            alert('success','הצלחה','הטופס הועלה בהצלחה')
            .then(()=>{
                reset()
            })
        }

        const save_document_in_db = async(document)=>{
            const doc = projectFirestore.collection('Applications').doc('1wEjkld8agzqXCaefeZ1').collection('Documents').doc()
            await doc.set({...document,id:doc.id})
        }

        const reset = ()=>{
            file.value = null
            document_name.value = ''
            img_preview.value = ''
            url.value = ''
            filePath.value = ''


        }
        return{
            handle_upload,
            handleChange,
            handle_submit,
            isPending,
            file,
            img_preview,
            document_name
        }
    }
}
</script>

<style scoped>
    .new-document{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .new-document-field{
        width: 100%;
        height: fit-content;
        padding: 0 5px;
        margin-top: 5px;
    }
    .new-document-field .title{
        font-size: 20px;
        color: #fff;
    }
    .file-preview{
        width: 100%;
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .file-preview img{
         max-width: 100%;
        max-height: 100%;
    }
    
</style>